.about {
  margin-bottom: 80px;
}
.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}
.about h2 {
  margin-bottom: 30px;
  color: #2d3954;
}
.about p {
  font-size: 13px;
  padding-right: 30px;
  padding-left: 30px;

  margin-bottom: 20px;
  line-height: 30px;
}
.about .btnn {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.about .btnn:hover {
  background-color: rgb(53, 74, 113);
}
.btn8 {
  background-color: rgb(62, 168, 101);
  margin: 5px;
  padding-left: 15px;
  padding-right: 15px;

  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.btn8:hover {
  background-color: rgb(53, 74, 113);
}


@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
  }
}
