section.dashbord {
  position: relative; /* Necessary for the pseudo-element positioning */
  height: 91.4vh;
  overflow-y: auto;
}


.info-account,
.dashbord-con {
  position: relative; /* Ensure content is above the overlay */
  z-index: 2; /* Ensure the content is above the overlay */
}
.dashbord .dashbord-con {
  display: flex;
  flex-direction: row-reverse;
  height: calc(100%-15px);
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.info-account {
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 50px 40px;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 1px -3px 6px rgba(112, 112, 112, 0.995);
  color: #000000;
  align-items: center;
}
.info-account .img-profile {
  display: flex;
}
.info-account img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 10px #2a5279;
}
.image-name {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: space-around;
}
.dashbord-title {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.dashbord .dashbord-header {
  background-color: #304c81;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin-top: -55px;
  padding: 1rem;
  width: 35%;
  border-radius: 10px;
  display: flex;
}

.dashbord .dashbord-sidebar {
  position: sticky;
  left: 0;
  top: 90px;
  background-color: #304c81;
  width: 200px;
  height: 85%;
  border-radius: 20px 0px 0px 20px;
  /* min-height: calc(1vh - 4rem); */
  padding: 1rem;
}

.dashbord .dashbord-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.dashbord .dashbord-sidebar ul li {
  border: 2px white solid;
  border-radius: 10px;
  padding: 10px;
  margin: 1rem 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.dashbord .dashbord-sidebar ul li Link {
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.dashbord .dashbord-sidebar ul li:hover {
  background-color: #1ab144; /* Darker blue */
  cursor: pointer;
}
.dashbord .content {
  overflow-y: auto;
  flex: 1;
  padding: 1rem;
  width: 100%;
  /* background-color: #ffffff99;
  backdrop-filter: blur(1px); */
}

/* تخصيص شريط التمرير */
.dashbord .content::-webkit-scrollbar {
  width: 12px; /* عرض شريط التمرير */
}

/* تخصيص المسار */
.dashbord .content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* تخصيص المقبض */
.dashbord .content::-webkit-scrollbar-thumb {
  background: #304c81;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* عند التمرير فوق المقبض */
.dashbord .content::-webkit-scrollbar-thumb:hover {
  background: #063773; /* لون المقبض عند التمرير فوقه */
}

/* @media (max-width: 768px) {
  .dashbord .dashbord-sidebar {
    width: 100%;
    height: auto;
  }

  .dashbord .content {
    padding: 0.5rem;
  }
} */
