.footerContact {
  background-color: #27ae60;
  padding: 40px 0;
  color: #fff;
}
.footerContact h1 {
  font-size: 40px;
}
footer {
  background-color: #1d2636;
  padding: 50px 0;
  color: #fff;
}
footer .container {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 1fr;
  grid-gap: 20px;
}
footer img {
  width: 180px;
}
footer h2 {
  font-weight: 500;
}
footer p {
  color: grey;
  margin: 15px 0;
}
footer h3 {
  font-weight: 400;
  margin-bottom: 15px;
}
footer ul {
  display: block;
}
footer ul li {
  display: block;
  color: grey;
  margin-bottom: 10px;
  font-size: 10px;
}
.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .icons {
  text-align: justify;
  font-size: 10px;
}
footer .icons i {
  font-size: 25px;
}
footer .btn-location {
  padding: 15px 10px;
  border-radius: 70px;
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  -ms-border-radius: 70px;
  -o-border-radius: 70px;
}
footer .btn-location:hover {
  background-color: rgba(224, 118, 42, 0.853);
}
footer .btn-location i {
  margin: 0 8px;
}

@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
  footer .icons i {
    font-size: 20px;
  }
  footer .logo h2 {
    font-size: 18px;
  }
  footer img {
    width: 170px;
    margin: auto -17px;
  }
  footer h3 {
    font-weight: 400;
    margin-bottom: 10px;
  }
  footer p {
    font-size: 11px;
    line-height: 20px;
  }
}
