.insert-forms .button {
  appearance: none;
  font: inherit;
  border: none;
  background: none;
  cursor: pointer;
}
.custom-slide {
  position: relative;
  display: inline-block;
}

.custom-slide img {
  display: block;
  width: 100%;
  height: auto;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.remove-post img {
  aspect-ratio: 7/5;
}

.insert-forms .modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 125, 171, 0.15);
  border-radius: 10px;
}

.insert-forms .modal__header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.insert-forms .modal__body {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem;
}

.insert-forms .modal__footer {
  padding: 0 1.5rem 1.5rem;
  margin-top: 55px;
}

.insert-forms .modal__title {
  font-weight: 700;
  font-size: 1.25rem;
}

.insert-forms .button--icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  border-radius: 0.25rem;
}

.insert-forms .button--icon:focus,
.insert-forms .button--icon:hover {
  background-color: #ededed;
}

.insert-forms .button--primary {
  background-color: #007dab;
  color: #fff;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
}

.insert-forms .button--primary:hover {
  background-color: #006489;
}

.insert-forms .input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.75rem;
  flex-wrap: wrap;
  margin: 10px;
  width: 45%;
  justify-content: space-evenly;
}

.insert-forms .input__label {
  font-weight: 700;
  font-size: 0.875rem;
}

.insert-forms .input__field {
  display: block;
  margin-top: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 0.75rem 0.75rem;
  transition: 0.15s ease;
}

.insert-forms .input__field:focus {
  outline: none;
  border-color: #007dab;
  box-shadow: 0 0 0 1px #007dab, 0 0 0 4px rgba(0, 125, 171, 0.25);
}

.insert-forms .input__field--textarea {
  min-height: 100px;
  max-width: 100%;
}
.row-input {
  display: flex;
}
