.dash .card {
  text-align: center;
  line-height: 30px;
  background-color: #1c1e67e8;
  color: #e1e1e1;
  border-radius: 10px;
  padding: 25px;
  font-weight: 400;
  font-size: 15px;
}
.dash .card div {
  margin: 5px;
}
.dash .email,
.dash .phone {
  border: #e1e1e1 solid 2px;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.dash .email a i,
.dash .phone a i {
  background-color: #eb962e;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dash .email a i:hover,
.dash .phone a i:hover {
  background-color: rgb(16, 152, 43);
}
.dash button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border: 0px solid transparent;
  background-color: rgb(255, 255, 255);
  border-radius: 1.25em;
  transition: all 0.2s linear;
}

.dash button:hover {
  background-color: #f8b25c;
  box-shadow: 3.4px 2.5px 4.9px rgba(0, 0, 0, 0.025),
    8.6px 6.3px 12.4px rgba(0, 0, 0, 0.035),
    17.5px 12.8px 25.3px rgba(0, 0, 0, 0.045),
    36.1px 26.3px 52.2px rgba(0, 0, 0, 0.055),
    99px 72px 143px rgba(0, 0, 0, 0.08);
}

.tooltip {
  position: relative;
  display: inline-block;
  background-color: rgb(210, 48, 30);
  padding: 10px 15px;
  text-align: center;
  border-radius: 25%;
  -webkit-border-radius: 25%;
  -moz-border-radius: 25%;
  -ms-border-radius: 25%;
  -o-border-radius: 25%;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 4em;
  background-color: rgba(0, 0, 0, 0.253);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.253) transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.svg-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
