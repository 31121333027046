@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Alexandria", sans-serif;
  font-weight: 700;
  background-color: #fff;
}
html {
  scroll-behavior: smooth; /* يجعل التمرير ناعمًا لجميع عناصر الصفحة بما في ذلك التحديث */
}

.container {
  max-width: 85%;
  margin: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}

button {
  border-radius: 5px;
  padding: 17px 30px;
  background: #2766ae;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}
button i {
  margin-right: 5px;
}
.heading {
  text-align: center;
  width: 60%;
  margin: auto;
}
.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 23px;
  margin-bottom: 3px;
}
.heading p {
  color: #72809d;
  font-size: 14px;
}
.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
label {
  color: #2d3954;
  font-size: 9px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 80px 0;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.services .grid3 > :nth-child(n + 4) {
  margin-left: 50%;
  margin-right: 50%;
}

.box {
  transition: transform 0.3s ease;
}
.box:hover {
  transform: translateY(-10px);
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.language-menu {
  border-radius: 5px;
  padding: 15px 2px;
  background: #36a036;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 11px;
}
.btn2 {
  border-radius: 50px;
  font-size: 15px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.btn2:hover {
  background: #36a036;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.btn3 {
  background: #ff6922;
  border-radius: 50px;
}
.btn4 {
  background-color: #000;
  color: #fff;
}
.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: #276dae;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #27ae601f;
  background: #fff;
}
.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}
.mb {
  margin-bottom: 80px;
}
.infor {
  background-color: #54547b;
  padding: 50px;
  margin: 20px;
  font-size: 12px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.btn-serv {
  padding: 12px 15px;
}
/* CSS */
/* CSS */
/* CSS */
.loader {
  position: fixed;
  top: 50%; /* يوضع العنصر في منتصف العرض بالنسبة للشاشة */
  left: 50%; /* يوضع العنصر في منتصف الارتفاع بالنسبة للشاشة */
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(43, 43, 45, 0.882); /* شفافية بنسبة 50% */
  z-index: 9999;
  display: flex; /* إظهار الصورة في مركز العنصر */
  justify-content: center; /* توسيط العنصر على المحور الأفقي */
  align-items: center; /* توسيط العنصر على المحور الرأسي */
}
.loader {
  width: 120%;
}
.loader img {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 120px;
  height: 120px; /* تأكد أن الطول يساوي العرض */
  object-fit: cover; /* للحفاظ على النسبة الأصلية للصورة داخل الدائرة */
}

.loader .circle {
  animation: spin 3s linear infinite;
  -webkit-animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1138px) {
  .grid4,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid3 {
    grid-template-columns: repeat(1, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
  .back h1 {
    font-size: 25px;
    font-weight: 500;
  }
  .services .grid3 > :nth-child(n + 4) {
    margin: auto;
  }
}

@media screen and (max-width: 1224px) and (min-width: 850px) {
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .services .grid3 > :nth-child(n + 4) {
    margin: auto;
  }
  .services .grid3 > :nth-child(n + 5) {
    margin-left: 50%;
    margin-right: 50%;
  }
}
@media screen and (max-width: 849) {
  .infor {
    font-size: 1px;
    padding: 25;
  }
  .infor p {
    font-weight: 300;
  }

  .services .grid3 {
    justify-items: center;
  }
}
