header {
  background-color: #fff;
  height: auto;
  padding: 10px;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 5px 0;
  position: sticky;
  font-weight: 900;
  top: 0;
  z-index: 100;
}
header img {
  width: 310px;
  padding: 0 10px;
  margin: 0 -90px;
}
.icons {
  text-align: center;
}

.icons a i {
  margin: 10px;
  font-size: 30px;
  color: floralwhite;
}

.icons a i:hover {
  transform: scale(1.1);
}

.hero-btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  color: #fff;
  margin-top: 30px;
  border: 1px solid #fff;
  padding: 5px 30px;
  font-size: 13px;
  background: transparent;
  position: relative;
  cursor: pointer;
}

.hero-btn:hover {
  border: 1px solid #379e37;
  background-color: #379e37;
  transition: 1s;
}
header a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
header a:hover {
  color: #379e37;
}
header span {
  width: auto;
  padding: 0px 6px;
  background: #2771ae;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}
header h4 {
  font-weight: 300;
  margin-right: 20px;
}
header .toggle {
  display: none;
}
.btn-login {
  all: unset;
  cursor: pointer;
  padding: 10px 15px;
  background-color: #1d5b95;
  font-family: "Alexandria", sans-serif;
  border-radius: 15px;
  color: #fff;
  font-size: 13px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.dashbor-div {
  display: flex;
}
.icon-dashbord {
  font-size: x-large;
  color: #092744;
}
.btnLanguage {
  all: unset;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 10px;
  background-color: #379e37;
  margin: 2px;
  color: #fff;
  font-weight: 500;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.btnLanguage:hover {
  color: #092744;
  background-color: rgb(210, 210, 210);
}
@media screen and (max-width: 1126px) and (min-width: 985px) {
  header img {
    margin: auto -40px;
    width: 230px;
  }

  header a {
    margin: auto 7px;
    font-size: 15px;
  }
}

@media screen and (max-width: 985px) {
  header {
    height: auto;
    padding-top: 10px;
  }
  header img {
    width: 200px;
    padding: 0 0 2px 0;
    margin: 0 0;
    margin-top: -3px;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle button {
    padding: 10px 15px;
  }
  header .toggle {
    display: block;
    margin-top: 1px;
  }
  header .small {
    position: absolute;
    top: 9vh;
    left: 0;
    width: 100%;
    background-color: #276dae;
    padding: 20px;
  }
  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  }
  .language-menu option {
    font-size: 8px;
  }
}

@media screen and (max-width: 800px) {
  header img {
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 2px;
  }
  header .small {
    position: absolute;
    top: 9vh;
    left: 0;
    width: 100%;
    background-color: #276dae;
    padding: 20px;
  }
  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  }
}
